import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import NavBar from "../components/nav"
import Image from "../components/image"

import "../styles/index.scss"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <NavBar />
    <div className="name">
      <h1>Dylan Black</h1>
    </div>
    <div className="about">
      <div className="about-section">
        <div className="emoji-section">
          <span className="emoji code">👨🏻‍💻</span>
        </div>
        <h3>I like to code</h3>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum
          porttitor congue ante et finibus. Nullam imperdiet, nisl ut maximus
          dapibus, lorem diam aliquam tellus, ac pharetra nunc nisl vitae nunc.
        </p>
      </div>
      <div className="about-section">
        <div className="emoji-section">
          <span className="emoji mountain">⛰️</span>
        </div>
        <h3>I like to hike</h3>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum
          porttitor congue ante et finibus. Nullam imperdiet, nisl ut maximus
          dapibus, lorem diam aliquam tellus, ac pharetra nunc nisl vitae nunc.
        </p>
      </div>
      <div className="about-section">
        <div className="emoji-section">
          <span className="emoji pizza">🍕</span>
        </div>
        <h3>I like to eat pizza</h3>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum
          porttitor congue ante et finibus. Nullam imperdiet, nisl ut maximus
          dapibus, lorem diam aliquam tellus, ac pharetra nunc nisl vitae nunc.
        </p>
      </div>
    </div>

    <Image src="../images/profile.jpeg" />
  </Layout>
)

export default IndexPage
