import React from "react"

const NavBar = () => {
  return (
    <header className="nav">
      <ul>
        <a href="#">
          <li>ABOUT</li>
        </a>
        <a href="#">
          <li>WORK</li>
        </a>
        <a href="#">
          <li>CONTACT</li>
        </a>
      </ul>
    </header>
  )
}

export default NavBar
